<template>
  <div class="page-title-div">
    <div class="left-div">
      <img src="../assets/return-black.png" alt="" @click="goBack">
    </div>
    <div class="middle-div">
      <label>{{ title }}</label>
    </div>
    <div class="right-div"></div>
  </div>
</template>

<script>
export default {
  name: "PageNavigationBar",
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style lang="less" scoped>
.page-title-div {
  width: 100%;
  height: 0.35rem;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .left-div {
    width: 0.28rem;
    height: 0.28rem;
    img {
      width: 0.28rem;
      height: 0.28rem;
    }
  }
  .middle-div {
    font-size: 0.18rem;
    font-weight: bold;
  }
  .right-div {
    width: 0.28rem;
    height: 0.28rem;
  }
}
</style>